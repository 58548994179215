/* Подключение шрифтов */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Montserrat:wght@700;800&family=Young+Serif&display=swap');

/* Подключение стилей для slick-carousel */
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}

.thumb {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.carousel .thumb.selected {
    border: 2px solid #077d92 !important;
    border-radius: 4px;
}

.carousel .thumb:hover {
    border: 2px solid #077d92 !important;
    border-radius: 4px;
}

.swiper {
    width: 100%;
    z-index: 100;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    z-index: 100;
}

.swiper-slide img {
    width: 100%;
}
.swiper-slide {
    overflow: hidden;
}

.swiper-pagination {
    margin-bottom: 50px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    padding: 50px;
    font-size: 50px;
    font-weight: 700;
}

.swiper-button-next,
.swiper-button-prev {
    width: 0;
}

.slick-dots {
    bottom: 15px;
}
.slick-dots li {
    width: 10px;
    height: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    color: #000;
}

*::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: none; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(199, 198, 198); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
}

.HiddenScroll::-webkit-scrollbar {
    display: none;
}
